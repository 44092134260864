import { useContext, useEffect, useMemo } from 'react';
import SessionContext from './sessionContext';
import fetch from 'react-storefront/fetch';
import get from 'lodash/get';
import { i18n } from '../../../tools/i18n';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { ErrorContext } from '../error';
import useUserStore from '../../../services/stores/UserStore';
import useCartStore from '@services/stores/CartStore';
import useSessionStore from '@services/stores/SessionStore';

/**
 * @typedef {Object} StorefrontContextType
 * @property {boolean} isSaleOnGoing - Indicates if a sale is ongoing.
 * @property {boolean} loading - Indicates if data is being loaded.
 * @property {Actions} actions - Collection of action methods.
 */

/**
 * @typedef {Object} PublicConfig
 * @property {string} name - The name of the store or business.
 * @property {string} description - A brief description of the store.
 * @property {string} keywords - A comma-separated list of keywords for SEO.
 * @property {Object} language - Information about the locale and language.
 * @property {string} language.locale - The locale, e.g., "sv".
 * @property {string} language.languageCode - The language code, e.g., "sv-SE".
 * @property {string} gtmId - The Google Tag Manager ID.
 * @property {string} fbPixelId - The Facebook Pixel ID.
 * @property {string} currency - The currency code, e.g., "SEK".
 * @property {string} myNewport - The URL for the Newport platform.
 * @property {string} elevateMarket - The market for Elevate, e.g., "SE".
 * @property {string} localtime - The current local time in ISO 8601 format.
 */

/**
 * @typedef {Object} Actions
 * @property {(country: *) => Promise<void>} setContextCountry - Sets the context country.
 * @property {(data: Object) => Promise<boolean|*>} updateCustomer - Updates customer information.
 * @property {(country: *) => Promise<void>} changeCountry - Changes the current country.
 * @property {(data: Object) => Promise<boolean|*>} registerCustomer - Registers a new customer.
 */

/**
 * @typedef {Object} SalesChannel
 * @property {Translated} translated - Translated content for the sales channel.
 */

/**
 * @typedef {Object} Translated
 * @property {SalesChannelCustomFields} customFields - Custom fields for translations.
 */

/**
 * @typedef {Object} SalesChannelCustomFields
 * @property {string} toplinks_campaign_text
 * @property {string} toplinks_campaign_link
 * @property {string} toplinks_outlet_link
 * @property {string} toplinks_customerservice_text
 * @property {string} toplinks_customerservice_link
 * @property {string} toplinks_outlet_text
 */

/**
 * @typedef {Object} Cart
 * @property {LineItem[]} lineItems - Items in the cart.
 * @property {Object} extensions - Additional cart extensions.
 * @property {CartPrice} price - Cart price information.
 * @property {boolean} loading - Indicates if the cart is loading.
 * @property {Object} errors - Cart errors.
 */

/**
 * @typedef {Object} CartPrice
 * @property {number} totalPrice - Total price of the cart.
 */

/**
 * @typedef {Object} LineItem
 * @property {string} id - Line item ID.
 * @property {string} name - Line item name.
 */


/**
 * @typedef {Object} Country
 * @property {string} id - Country ID.
 * @property {string} name - Country name.
 */


/**
 * @typedef {Object} Language
 * @property {string} code - Language code.
 * @property {string} name - Language name.
 */

/**
 * @typedef {Object} Currency
 * @property {string} code - Currency code.
 * @property {string} symbol - Currency symbol.
 */

/**
 * Fetches user session data from a specific URL and provides it to descendant components via `SessionContext`.
 *
 * User and session data such as the number of items in the cart, the user's name, and email should always be
 * fetched when the app mounts, not in `getInitialProps`, otherwise the SSR result would not be cacheable
 * since it would contain user-specific data.
 */
export default function SessionProvider({ url, config, children }) {
    const userInit = useUserStore((state) => state.init);
    const updateAccount = useUserStore((state) => state.updateAccount);
    const setConfig = useUserStore((state) => state.setConfig);
    const isLoggedIn = useUserStore((state) => state.isLoggedIn);
    const cartMethods = useCartStore((state) => state.methods);
    const router = useRouter();
    const init = useSessionStore((state) => state.init);
    const { getEclubData, setSession } = useSessionStore((state) => state.methods);
    const token = useSessionStore((state) => state.session?.token);
    const language = useSessionStore((state) => state.session.config.language.locale);
    const { actions: errorActions } = useContext(ErrorContext);

    console.log('SessionContext', errorActions, token);

    useMemo(() => {
        if (!init) {
            setConfig(config);
            setSession({ config });
        }
    }, [ config ]);

    useEffect(() => {
        const cartRecovery = getCartRecoveryCookie();
        if (cartRecovery) {
            fetchSession(cartRecovery).then(() => null);
        } else {
            fetchSession().then(() => null);
        }
    }, []);

    /**
     * Wishlist
     */
    useEffect(async () => {
        if (isLoggedIn) {
            updateAccount().then(r => null);
        }
        await userInit();
    }, [ isLoggedIn ]);

    useEffect(async () => {
        console.log('change language and session');

        if (language && language !== i18n.language && i18n.options.supportedLngs.indexOf(language) >= 0) {
            await i18n.changeLanguage(language);
        }

        /**
         * Session token
         */
        console.log('token session');
        const {
            query: {
                couponCode,
            },
        } = router;

        if (token && couponCode) {
            document.addEventListener('eclub-login', () => {
                console.log('eclub-login');
                applyCouponCodeFromUrl(couponCode);
            });

            document.addEventListener('eclub-missing', () => {
                console.log('eclub-missing');
                applyCouponCodeFromUrl(couponCode);
            });
        }

        return () => {
            document.removeEventListener('eclub-login', applyCouponCodeFromUrl);
            document.removeEventListener('eclub-missing', applyCouponCodeFromUrl);
        };
    }, [ token, language ]);

    /** @type {StorefrontContextType} */
    const context = useMemo(() => {
        console.log('StorefrontContextType memes');
        /**
         * @type {StorefrontContextType}
         */
        const memoContext = {
            paymentMethod: null,
            isSaleOngoing: false,
            loading: true,
            actions: {
                /**
                 * Change country
                 */
                async changeCountry(country) {
                    const response = await fetch('/api/countries/set', {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                        body: JSON.stringify({
                            country,
                        }),
                    });

                    const result = await response.json();

                    if (result.status === 'success') {
                        await fetchSession();
                        await cartMethods.getCart();
                    } else {
                        throw new Error(
                            get(
                                result,
                                'error',
                                'An unknown error occurred while attempting to update country.',
                            ),
                        );
                    }
                },

                /**
                 * Register customer used with Adyen checkout
                 * @param customerData
                 * @returns {Promise<boolean|*>}
                 */
                async registerCustomer(customerData) {
                    const response = await fetch('/api/session/register', {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                        body: JSON.stringify(customerData),
                    });

                    const result = await response.json();

                    if (result.status === 'success') {
                        let accessToken = result.accessToken;

                        await fetchSession(accessToken);
                        await cartMethods.getCart();

                        return result.customer;
                    } else {
                        return result;
                        /*throw new Error(
                            get(
                                result,
                                'error',
                                'An unknown error occurred while attempting register customer.',
                            ),
                        ); */
                    }
                },

                /**
                 * Update customer used with Adyen checkout
                 * @returns {Promise<boolean|*>}
                 * @param email
                 * @param customer
                 * @param billingAddressId
                 * @param billingAddress
                 * @param shippingAddressId
                 * @param shippingAddress
                 */
                async updateCustomer(email, customer, billingAddressId, billingAddress, shippingAddressId, shippingAddress) {
                    const response = await fetch('/api/session/update', {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                        body: JSON.stringify({
                            email,
                            customer,
                            billingAddressId,
                            billingAddress,
                            shippingAddressId,
                            shippingAddress,
                        }),
                    });

                    const result = await response.json();

                    if (result.status === 'success') {
                        await fetchSession();
                        return result.customer;
                    } else {
                        throw new Error(
                            get(
                                result,
                                'error',
                                'An unknown error occurred while attempting register customer.',
                            ),
                        );
                    }
                },

                async setContextCountry(countryId) {
                    if (!countryId) {
                        return;
                    }

                    try {
                        let response = await fetch('/api/checkout/set-context-country', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                countryId,
                            }),
                        });

                        console.log(response);

                        await fetchSession();
                        await cartMethods.getCart();
                    } catch (e) {
                        console.log(e);
                    }
                },
            },
        };
        return memoContext;
    }, []);

    /////
    ///	End hooks & Start functions
    /////
    function checkCartForErrors(errors) {
        let errorKeys = Object.keys(errors);
        let errorFound = false;
        let errorKey = '';

        errorKeys.forEach((key) => {
            if (errors[key].level > 0) {
                errorFound = key;
                errorKey = key;
            }
        });

        return {
            found: errorFound ? true : false,
            key: errorKey,
            message: errorFound ? 'Promotion not found' : undefined,
        };
    }

    async function fetchSession(cartRecoveryCookie = false) {
        let sessionApiUrl = url;

        if (cartRecoveryCookie) {
            sessionApiUrl = sessionApiUrl + '?cartRecovery=' + cartRecoveryCookie;
        }

        const response = await fetch(sessionApiUrl, {
            method: 'get',
            credentials: 'include',
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
        });

        const result = await response.json();

        console.log('fetch session', result);
        setSession(result);
    }

    function getCartRecoveryCookie() {
        let cartRecoveryToken = router.query ? router.query.cartRecovery : false;

        if (cartRecoveryToken) {
            let previousSessionToken = Cookies.get(process.env.NEXT_PUBLIC_STOREFRONT_SESSION_COOKIE);

            if (previousSessionToken !== cartRecoveryToken) {
                // Trigger event listeners to track cart updates
                document.dispatchEvent(new CustomEvent('cart-recovered', {
                    detail: {
                        previousSessionToken: previousSessionToken,
                    },
                }));
            }

            return cartRecoveryToken;
        }

        return false;
    }

    async function applyCouponCodeFromUrl(couponCode) {
        let eClubData = getEclubData();
        let error = false;

        if (eClubData) {
            error = await cartMethods.applyCoupon(couponCode, eClubData.email);
        } else {
            error = await cartMethods.applyCoupon(couponCode);
        }

        let errors = checkCartForErrors(error);

        if (error.status !== 'missing-email' && !errors.found) {
            // Trigger success message
            errorActions.addError({
                type: 'success',
                messageKey: 'discount-code-success',
                messageData: {
                    code: couponCode,
                },
            });
        }
    }

    return <SessionContext.Provider value={context}>
        {children}
    </SessionContext.Provider>;
}
