import { makeStyles } from '@material-ui/styles';
import theme from '../../../theme';
import { useEffect, useState } from 'react';
import useSessionStore from '@services/stores/SessionStore';
import useApplicationStore from '@services/stores/ApplicationStore';

const useStyles = makeStyles({
    labels: {
        position: 'absolute',
        top: 0,
        right: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: 10,
        [theme.breakpoints.up('md')]: {
            top: 0,
        },
        '&.large': {
            top: 10,
            right: 10,
        },
    },
    marketingLabel: {
        background: '#000',
        color: '#fff',
        padding: '4px 10px'
    },
    discountBubble: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2px 10px 1px 10px',
        whiteSpace: 'nowrap',
        fontSize: 10,
        fontWeight: 600,
        lineHeight: '22px',
        background: '#000',
        color: '#fff',
        pointerEvents: 'none',
        '.large &': {
            fontSize: 13,
            padding: '8px 20px',
        }
    },
});

export default function ProductLabels(
    {
        available,
        streamIds = [],
        propertyIds = [],
        prices,
        large = false,
    }
) {
    const appMethods = useApplicationStore((state) => state.methods);
    const sessionActions = useSessionStore((state) => state.methods);
    const classes = useStyles();
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        // Set to true only when the component has mounted (client-side)
        setIsClient(true);
    }, []);

    if (!isClient) return null; // Don't render on the server

    const marketingLabels = appMethods.getMarketingLabels(propertyIds);
    const size = large ? 'large' : 'small';

    function renderStreamIds(productStreamIds) {
        if (!Array.isArray(productStreamIds) || !(productStreamIds.length > 0)) {
            return null;
        }

        const labels = {};

        function renderLabel(label) {
            return <span key={'disco-bubble'} className={[ classes.discountBubble, 'discountBubble', 'black-week', size ].join(' ')}>
                {label}
            </span>;
        }

        if (!sessionActions.isSalesChannelNordic()) {
            return null;
        }

        if (prices.discount) {
            return null;
        }

        if (!available) {
            return null;
        }

        return null;
    }

    let labels = [];
    if (marketingLabels?.length) {
        labels = marketingLabels.map((label, index) => <span
            key={`${label}-${index}`}
            className={[ classes.marketingLabel ].join(' ')}
        >
            {label}
        </span>);
    }

    if (prices.discount) {
        labels.push(<span key={`label-0`} className={[classes.discountBubble].join(' ')}>{Math.floor(prices.discount.percentage)}%</span>);
    }

    labels.push(renderStreamIds(streamIds));
    if (labels?.length <= 0) {
        return null;
    }

    return (<div className={[ classes.labels, size ].join(' ')}>
        {labels}
    </div>);
}
